import React, { useContext } from "react"
import { Route, Redirect } from "react-router-dom"
import { UserContext } from "~/helpers/context"

export const PrivateRoute = ({ component: Component, ...rest }) => {
  const userContext = useContext(UserContext)

  return (
    <Route
      {...rest}
      render={props =>
        userContext.values.loggedIn ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: "/login", state: { from: props.location } }} />
        )
      }
    />
  )
}
