import IconButton from "@material-ui/core/IconButton"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableRow from "@material-ui/core/TableRow"
import React, { useState, useRef } from "react"
import Calendar from "react-calendar"
import { useMediaQuery } from "react-responsive"
import { useReactToPrint } from "react-to-print"
import NurseSelect from "~/components/NurseSelect"
import PrintIcon from "~/components/icons/PrintSVG"
import { NursesContext, UserContext } from "~/helpers/context"
import { careService } from "~/services/care"
import "./MonthPage.scss"

function occurences_renderder(occurences) {
  const result_list = []
  occurences.forEach((occurence, index) => {
    if (occurence.color === "red") {
      result_list.push(
        <span key={index} className="redText">
          {occurence.text}{" "}
        </span>
      )
    } else {
      result_list.push(<span key={index}>{occurence.text} </span>)
    }
  })
  return result_list
}

function patient_renderer(patient, patient_index) {
  const result = []
  patient.cares.forEach((care, care_index) => {
    const text = occurences_renderder(care.occurences)
    if (care_index === 0) {
      result.push(
        <TableRow key={patient_index * 10000 + care_index}>
          <TableCell>{patient.patient}</TableCell>
          <TableCell>{care.care_name}</TableCell>
          <TableCell>{text}</TableCell>
        </TableRow>
      )
    } else {
      result.push(
        <TableRow key={patient_index * 10000 + care_index}>
          <TableCell></TableCell>
          <TableCell>{care.care_name}</TableCell>
          <TableCell>{text}</TableCell>
        </TableRow>
      )
    }
  })
  return result
}

export default function MonthPage(props) {
  const userContext = React.useContext(UserContext)
  const nursesContext = React.useContext(NursesContext)

  const [date, setDate] = React.useState(new Date())
  const [patients, setPatients] = React.useState([])
  const [selectedNurseId, setSelectedNurseId] = React.useState(userContext.values.nurseId)

  const onDateChange = date => {
    setDate(date)
  }

  const [printable, setPrintable] = useState(false)

  const calendarRef = useRef()

  const get_nurse_name = nurse_id => {
    if (nursesContext.values.nursesList) {
      const found = nursesContext.values.nursesList.find(
        element => element["nurse_id"] === nurse_id
      )
      if (found) {
        return found["name"]
      }
    }
    return null
  }

  const update = React.useCallback(() => {
    const [, month, year] = date.toLocaleDateString().split("/")
    const date_str = "" + year + "-" + month

    careService.get_total(selectedNurseId, date_str).then(patient_list => {
      setPatients(patient_list)
    })
  }, [date, selectedNurseId])

  React.useEffect(() => {
    if (selectedNurseId) {
      update()
    } else {
      setPatients([])
    }
  }, [selectedNurseId, date, update])

  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-device-width: 1224px)",
  })

  const componentRef = useRef()

  const dateOptions = { month: "long", year: "numeric" }
  const dateOptionsMobile = { month: "numeric", year: "numeric" }

  let line_elements = []
  patients.forEach((patient, patient_index) => {
    const current_patient_elements = patient_renderer(patient, patient_index)
    line_elements = line_elements.concat(current_patient_elements)
  })

  const handleBeforeGetContent = () => {
    return new Promise((resolve, reject) => {
      setPrintable(true)
      resolve()
    })
  }

  const handlePrint = useReactToPrint({
    content: () => calendarRef.current,
    onAfterPrint: () => {
      setPrintable(false)
    },
    onBeforeGetContent: handleBeforeGetContent,
  })
  return (
    <div ref={calendarRef}>
      <div className="title">
        {isDesktopOrLaptop ? (
          <h1>{date.toLocaleDateString("fr-FR", dateOptions)}</h1>
        ) : (
          <h1>{date.toLocaleDateString("fr-FR", dateOptionsMobile)}</h1>
        )}
      </div>
      <div>
        {!printable && (
          <div className="selectNurse">
            {userContext.values.role === "admin" && (
              <NurseSelect
                personalAgenda={true}
                setNurse={setSelectedNurseId}
                value={selectedNurseId}
              />
            )}
          </div>
        )}
      </div>
      {!printable && (
        <div className="calendar">
          {selectedNurseId && (
            <div className="calendar">
              <Calendar locale="fr" maxDetail="year" value={date} onChange={onDateChange} />
            </div>
          )}
        </div>
      )}
      {!printable && (
        <div className="print">
          <IconButton className="printIcon" id="print" onClick={handlePrint}>
            <PrintIcon />
            {isDesktopOrLaptop && <div className="printText">Imprimer</div>}
          </IconButton>
        </div>
      )}

      <div ref={componentRef} className="monthContent">
        <div className="billing_header">
          <h2>{get_nurse_name(selectedNurseId)}</h2>
        </div>
        <Table>
          <TableBody>{line_elements}</TableBody>
        </Table>
      </div>
    </div>
  )
}
