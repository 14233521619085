import React from "react"
import Calendar from "react-calendar"
import { useMediaQuery } from "react-responsive"
import ClipLoader from "react-spinners/ClipLoader"
import GenericCalendarPage from "../GenericCalendar/GenericCalendarPage"
import NurseSelect from "~/components/NurseSelect"
import { UserContext } from "~/helpers/context"
import { useGetCareForNurse } from "~/hooks/api"

import "./PersonalCalendarPage.scss"

export default function PersonalCalendarPage(props) {
  const userContext = React.useContext(UserContext)

  const [date, setDate] = React.useState(new Date())
  const [selectedNurseId, setSelectedNurseId] = React.useState(userContext.values.nurseId)
  const { isLoading, error, data: cares } = useGetCareForNurse(date, selectedNurseId)

  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-device-width: 1224px)",
  })

  if (error) {
    return <p>{error.message}</p>
  }

  const dateOptions = { day: "numeric", month: "long", weekday: "long", year: "numeric" }

  return (
    <div>
      <div className="title">
        {isDesktopOrLaptop ? (
          <h1>{date.toLocaleDateString("fr-FR", dateOptions)}</h1>
        ) : (
          <h1>{date.toLocaleDateString("fr-FR")}</h1>
        )}
      </div>
      <div>
        <div className="selectNurse">
          {userContext.values.role === "admin" && (
            <NurseSelect
              personalAgenda={true}
              setNurse={setSelectedNurseId}
              value={selectedNurseId}
            />
          )}
        </div>
        <div className="calendar">
          {selectedNurseId && (
            <div className="calendar">
              <Calendar locale="fr" value={date} onChange={setDate} />
            </div>
          )}
        </div>
      </div>
      {isLoading ? (
        <div className="loader">
          <ClipLoader color={"#6B6F8F"} size={100} />
        </div>
      ) : (
        <GenericCalendarPage cares={cares} personal={true} />
      )}
    </div>
  )
}
