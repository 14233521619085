import FormControl from "@material-ui/core/FormControl"
import InputLabel from "@material-ui/core/InputLabel"
import MenuItem from "@material-ui/core/MenuItem"
import Select from "@material-ui/core/Select"
import { makeStyles } from "@material-ui/core/styles"
import React, { useContext } from "react"
import { NursesContext } from "~/helpers/context"

const useStyles = makeStyles(theme => ({
  formControl: {
    minWidth: 120,
  },
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  selectEmpty: {},
}))

export default function NurseSelect(props) {
  const classes = useStyles()

  const handleChange = event => {
    const newNurseId = event.target.value
    props.setNurse(newNurseId)
  }

  const nursesContext = useContext(NursesContext)
  if (!nursesContext.values.nursesList) {
    return <div />
  }

  if (props.printable) {
    if (!props.value) {
      return <p>-</p>
    }
    const nurseName = nursesContext.values.nursesList.filter(e => e.nurse_id === props.value)[0]
      ?.name
    return <p>{nurseName}</p>
  }
  return (
    <form autoComplete="off" className={classes.root}>
      <FormControl className={classes.formControl}>
        <InputLabel>{props.personalAgenda ? "Infirmier" : "Attribuer"}</InputLabel>
        <Select value={props.value ? props.value : ""} onChange={handleChange}>
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          {nursesContext.values.nursesList.map((element, index) => (
            <MenuItem key={index} value={element.nurse_id}>
              {element.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </form>
  )
}
