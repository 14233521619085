import SvgIcon from "@material-ui/core/SvgIcon"
import React from "react"

const AddIcon = props => (
  <SvgIcon className="printIconSvg" viewBox="0 0 49 49">
    <g transform="translate(-318 -571.721)">
      <circle
        className="addCircle"
        cx="24.5"
        cy="24.5"
        r="24.5"
        transform="translate(318 571.721)"
      />
      <path
        className="addPath"
        d="M25.91,13.212H16.941V4.243A1.993,1.993,0,0,0,14.948,2.25H12.955a1.993,1.993,0,0,0-1.993,1.993v8.969H1.993A1.993,1.993,0,0,0,0,15.205V17.2a1.993,1.993,0,0,0,1.993,1.993h8.969V28.16a1.993,1.993,0,0,0,1.993,1.993h1.993a1.993,1.993,0,0,0,1.993-1.993V19.191H25.91A1.993,1.993,0,0,0,27.9,17.2V15.205A1.993,1.993,0,0,0,25.91,13.212Z"
        transform="translate(328.63 580.38)"
      />
    </g>
  </SvgIcon>
)

export default AddIcon
