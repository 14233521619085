import { common } from "./common"
import { config } from "~/config"
import { authHeader } from "~/helpers/auth-header"

export const loginService = {
  changePassword,
  login,
  logout,
  validate,
}

function login(username, password) {
  const requestOptions = {
    body: JSON.stringify({ password, username }),
    headers: { "Content-Type": "application/json" },
    method: "POST",
  }

  return fetch(config.serverAddress + "/users/authenticate", requestOptions)
    .then(common.handleResponse)
    .then(user => {
      // login successful if there's a user in the response
      if (user) {
        // store user details and basic auth credentials in local storage
        // to keep user logged in between page refreshes
        user.authdata = window.btoa(username + ":" + password)
        user.username = username
        localStorage.setItem("user", JSON.stringify(user))
      }

      return user
    })
}

function validate(password) {
  const persitentUser = JSON.parse(localStorage.getItem("user"))
  const username = persitentUser.username

  const requestOptions = {
    body: JSON.stringify({ password, username }),
    headers: { "Content-Type": "application/json" },
    method: "POST",
  }

  return fetch(config.serverAddress + "/users/authenticate", requestOptions)
    .then(common.handleResponse)
    .then(user => {
      // login successful if there's a user in the response
      if (user) {
        return true
      }
      return false
    })
}

function changePassword(password) {
  const persitentUser = JSON.parse(localStorage.getItem("user"))
  const username = persitentUser.username

  const requestOptions = {
    body: JSON.stringify({ password }),
    headers: { ...authHeader(), "Content-Type": "application/json" },
    method: "POST",
  }

  return fetch(config.serverAddress + "/change_password", requestOptions)
    .then(common.handleResponse)
    .then(user => {
      // login successful if there's a user in the response
      if (user) {
        user.authdata = window.btoa(username + ":" + password)
        user.username = username
        localStorage.setItem("user", JSON.stringify(user))
        return true
      }
      return false
    })
}

function logout() {
  // remove user from local storage to log user out
  localStorage.removeItem("user")
}
