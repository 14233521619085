import Button from "@material-ui/core/Button"
import Container from "@material-ui/core/Container"
import Modal from "@material-ui/core/Modal"
import TextField from "@material-ui/core/TextField"
import { makeStyles } from "@material-ui/core/styles"
import React, { useState } from "react"
import { ToastsContainer, ToastsStore, ToastsContainerPosition } from "react-toasts"
import { loginService } from "~/services/authentication"

import "./SettingsPage.scss"

const useStyles = makeStyles(theme => ({
  modal: {
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    padding: theme.spacing(1),
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: 400,
  },
  root: {
    // The position fixed scoping doesn't work in IE 11.
    // Disable this demo to preserve the others.
    "@media all and (-ms-high-contrast: none)": {
      display: "none",
    },

    flexGrow: 1,

    height: 300,

    minWidth: 300,

    transform: "translateZ(0)",
  },
}))

export default function SettingsPage(props) {
  const classes = useStyles()
  const [modalOpen, setModalOpen] = useState(false)

  const [currentPassword, setCurrentPassword] = useState()
  const [newPassword1, setNewPassword1] = useState()
  const [newPassword2, setNewPassword2] = useState()

  const handlePasswordChange = async () => {
    if (newPassword1 !== newPassword2) {
      ToastsStore.error("Les nouveaux mot de passe sont différents")
      return
    }

    try {
      await loginService.validate(currentPassword)
    } catch {
      ToastsStore.error("Mot de passe actuel invalide")
      return
    }

    await loginService.changePassword(newPassword1)

    ToastsStore.success("Nouveau mot de passe configuré")
    setModalOpen(false)
  }

  const onChange = setter => event => {
    setter(event.target.value)
  }

  return (
    <div className="settings">
      <div className={classes.root}>
        <div className="title">
          <h1>Paramètres</h1>
        </div>
        <Container maxWidth="sm">
          <div className="content">
            <Button color="secondary" variant="contained" onClick={() => setModalOpen(true)}>
              Change password
            </Button>
          </div>
        </Container>
        <Modal
          aria-describedby="simple-modal-description"
          aria-labelledby="simple-modal-title"
          className={classes.modal}
          open={modalOpen}
          onClose={() => setModalOpen(false)}
        >
          <div className={classes.paper}>
            <p>Changement de mot de passe</p>
            <div>
              <TextField
                label="Mot de passe actuel"
                type="password"
                onChange={onChange(setCurrentPassword)}
              />
              <TextField
                label="Nouveau mot de passe"
                type="password"
                onChange={onChange(setNewPassword1)}
              />
              <TextField
                label="Nouveau mot de passe"
                type="password"
                onChange={onChange(setNewPassword2)}
              />
            </div>
            <div className="buttonContainer">
              <Button color="primary" variant="contained" onClick={() => setModalOpen(false)}>
                Annuler
              </Button>
              <Button color="primary" variant="contained" onClick={handlePasswordChange}>
                Valider
              </Button>
            </div>
          </div>
        </Modal>
      </div>
      <ToastsContainer position={ToastsContainerPosition.BOTTOM_CENTER} store={ToastsStore} />
    </div>
  )
}
