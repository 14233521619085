import { common } from "./common"
import { config } from "~/config"
import { authHeader } from "~/helpers/auth-header"

export const patientService = {
  create,
  get,
  get_one,
  remove,
  update,
}

function get() {
  const requestOptions = {
    headers: authHeader(),
    method: "GET",
  }

  return fetch(config.serverAddress + "/patients", requestOptions)
    .then(common.handleResponse)
    .then(patient => {
      return patient.patients
    })
}

function get_one(patient_id) {
  const requestOptions = {
    headers: authHeader(),
    method: "GET",
  }

  return fetch(config.serverAddress + "/patient/" + patient_id, requestOptions)
    .then(common.handleResponse)
    .then(patient => {
      return patient
    })
}

function create(new_patient) {
  const requestOptions = {
    body: JSON.stringify(new_patient),
    headers: { "Content-Type": "application/json", ...authHeader() },
    method: "POST",
  }

  return fetch(config.serverAddress + "/patients", requestOptions).then(response => {
    return response
  })
}

function update(existing_patient) {
  const requestOptions = {
    body: JSON.stringify(existing_patient),
    headers: { "Content-Type": "application/json", ...authHeader() },
    method: "PUT",
  }

  return fetch(config.serverAddress + "/patient/" + existing_patient["patient_id"], requestOptions)
    .then(common.handleResponse)
    .then(response => {
      return response
    })
}

function remove(patient_id) {
  const requestOptions = {
    headers: { "Content-Type": "application/json", ...authHeader() },
    method: "DELETE",
  }

  return fetch(config.serverAddress + "/patient/" + patient_id, requestOptions).then(response => {
    return response
  })
}
