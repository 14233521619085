import React from "react"
import { Link } from "react-router-dom"

import "./Menu.scss"

function MenuLink(props) {
  return (
    <Link className="menu-item" to={props.link} onClick={props.closeMenu}>
      <img alt="menu item representation" className="menu_image" src={props.icon} />
      <span>{props.title}</span>
    </Link>
  )
}

export default MenuLink
