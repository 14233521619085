import React, { useContext, useState } from "react"
import { slide as Menu } from "react-burger-menu"
import MenuLink from "./MenuLink"
import { UserContext } from "~/helpers/context"
import adminIcon from "~/resources/icons/admin.svg"
import billingIcon from "~/resources/icons/billing.svg"
import careIcon from "~/resources/icons/cares.svg"
import calendarIcon from "~/resources/icons/general_calendar.svg"
import logoutIcon from "~/resources/icons/logout.svg"
import menuIcon from "~/resources/icons/menu.svg"
import patientIcon from "~/resources/icons/patients.svg"
import calendarPersoIcon from "~/resources/icons/personal_calendar.svg"
import closeIcon from "~/resources/icons/pink_cross.svg"
import settingsIcon from "~/resources/icons/settingsIcon.png"

import "./Menu.scss"

function MyMenu(props) {
  const userContext = useContext(UserContext)
  const is_admin = userContext.values.role === "admin"
  const [menuState, setMenuState] = useState(false)
  const closeMenu = () => {
    setMenuState(false)
  }

  const isMenuOpen = function (state) {
    setMenuState(state.isOpen)
  }

  return (
    <Menu
      className={"sidebar"}
      customBurgerIcon={<img alt="Icone de Menu" src={menuIcon} />}
      customCrossIcon={<img alt="close menu" src={closeIcon} />}
      isOpen={menuState}
      onStateChange={isMenuOpen}
    >
      {is_admin ? (
        <MenuLink
          closeMenu={closeMenu}
          icon={adminIcon}
          link="/managerUser"
          title="Administration"
        />
      ) : null}
      <MenuLink closeMenu={closeMenu} icon={patientIcon} link="/patient" title="Patients" />
      <MenuLink closeMenu={closeMenu} icon={careIcon} link="/care" title="Soins" />
      <MenuLink closeMenu={closeMenu} icon={calendarIcon} link="/calendar" title="Agenda général" />
      {(is_admin || userContext.values.nurseId) && (
        <MenuLink
          closeMenu={closeMenu}
          icon={calendarPersoIcon}
          link="/personalcalendar"
          title="Agenda personnel"
        />
      )}
      <MenuLink closeMenu={closeMenu} icon={billingIcon} link="/month" title="Total du mois" />
      <MenuLink closeMenu={closeMenu} icon={settingsIcon} link="/settings" title="Paramètres" />
      <MenuLink closeMenu={closeMenu} icon={logoutIcon} link="/login" title="Déconnexion" />
    </Menu>
  )
}

export default MyMenu
