import MaterialTable from "material-table"
import React from "react"
import { NursesContext } from "~/helpers/context"
import { nurseService } from "~/services/nurse"

export default function NurseTable({ users, nurses, setNurses }) {
  const { setter: nurse_context_setter } = React.useContext(NursesContext)

  const refresh = React.useCallback(() => {
    nurseService.get().then(nurse_list => {
      setNurses(nurse_list)
      nurse_context_setter({ nursesList: nurse_list })
    })
  }, [setNurses, nurse_context_setter])

  React.useEffect(() => {
    refresh()
  }, [refresh])

  const onRowAdd = newData =>
    new Promise((resolve, reject) => {
      nurseService.create(newData).then(() => {
        refresh()
        resolve()
      })
    })

  const onRowUpdate = (newData, oldData) =>
    new Promise((resolve, reject) => {
      nurseService.update(newData).then(() => {
        refresh()
        resolve()
      })
    })

  const onRowDelete = oldData =>
    new Promise((resolve, reject) => {
      nurseService.remove(oldData.nurse_id).then(() => {
        refresh()
        resolve()
      })
    })

  const user_lookup = {}
  users.forEach(user => {
    user_lookup[user.id] = user.username
  })
  let initialId = null
  if (users.length) {
    initialId = users[0].id
  }
  return (
    <MaterialTable
      columns={[
        { field: "name", title: "Nom" },
        {
          field: "user_id",
          initialEditValue: initialId,
          lookup: user_lookup,
          title: "Utilisateur",
        },
      ]}
      data={nurses}
      editable={{ onRowAdd, onRowDelete, onRowUpdate }}
      localization={{
        body: {
          addTooltip: "Ajouter",
          deleteTooltip: "Supprimer",
          editRow: {
            cancelTooltip: "Annuler",
            saveTooltip: "Sauver",
          },
          editTooltip: "Modifier",
          emptyDataSourceMessage: "Aucune donnée à afficher",
        },
        pagination: {
          firstTooltip: "Première page",
          labelDisplayedRows: "{from}-{to} sur {count}",
          labelRowsSelect: "lignes",
          lastTooltip: "Dernière page",
          nextTooltip: "Suivant",
          previousTooltip: "Précédent",
        },
        toolbar: {
          searchPlaceholder: "Rechercher",
        },
      }}
      title="Infirmiers"
    />
  )
}
