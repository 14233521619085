import SvgIcon from "@material-ui/core/SvgIcon"
import React from "react"

const DeleteIcon = props => (
  <SvgIcon className="deleteIconSvg" height="28.595" viewBox="0 0 26.035 28.595" width="26.035">
    <g transform="translate(1.5 1.5)">
      <path className="deleteA" d="M4.5,9H27.535" transform="translate(-4.5 -3.881)" />
      <path
        className="deleteA"
        d="M25.416,8.119V26.035a2.559,2.559,0,0,1-2.559,2.559h-12.8A2.559,2.559,0,0,1,7.5,26.035V8.119m3.839,0V5.559A2.559,2.559,0,0,1,13.9,3h5.119a2.559,2.559,0,0,1,2.559,2.559V8.119"
        transform="translate(-4.941 -3)"
      />
      <path className="deleteA" d="M15,16.5v7.678" transform="translate(-6.042 -4.982)" />
      <path className="deleteA" d="M21,16.5v7.678" transform="translate(-6.923 -4.982)" />
    </g>
  </SvgIcon>
)

export default DeleteIcon
