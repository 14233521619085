import Button from "@material-ui/core/Button"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import InputAdornment from "@material-ui/core/InputAdornment"
import TextField from "@material-ui/core/TextField"
import React from "react"
import Calendar from "react-calendar"
import { UserContext } from "~/helpers/context"
import "./NoteEditor.css"

export default function NoteEditor(props) {
  const [state, setState] = React.useState({
    duration: 1,
    message: "",
    startDate: new Date(),
  })

  const userContext = React.useContext(UserContext)

  const handleSubmite = () => {
    const [startDay, startMonth, startYear] = state.startDate.toLocaleDateString().split("/")
    const startDate_str = "" + startYear + "-" + startMonth + "-" + startDay

    const data = {
      duration: parseInt(state.duration),
      message: state.message,
      nurse_id: userContext.values.nurseId,
      start_date: startDate_str,
    }

    props.addNote(data, () => {
      props.setEditorShown(false)
    })
  }

  const handleClose = () => {
    props.setEditorShown(false)
  }

  const handleEventChange = name => event => {
    setState({ ...state, [name]: event.target.value })
  }

  const handleDateChange = name => date => {
    setState({ ...state, [name]: date })
  }

  return (
    <Dialog aria-labelledby="form-dialog-title" open={props.editorShown} onClose={handleClose}>
      <div>
        <DialogTitle id="form-dialog-title">Ajouter une note</DialogTitle>
        <DialogContent>
          <div className="dateMessage">
            <div className="dateColumn">
              <p>Date de début :</p>
              <Calendar
                locale="fr"
                value={state.startDate}
                onChange={handleDateChange("startDate")}
              />
              <TextField
                InputProps={{
                  endAdornment: <InputAdornment position="end">jour(s)</InputAdornment>,
                }}
                label="Durée"
                margin="normal"
                type="number"
                value={state.duration}
                onChange={handleEventChange("duration")}
              />
            </div>
            <div className="dateColumn">
              <p>Message :</p>
              <TextField
                margin="normal"
                multiline
                value={state.message}
                onChange={handleEventChange("message")}
              />
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={handleClose}>
            Annuler
          </Button>
          <Button color="primary" onClick={handleSubmite}>
            Ajouter
          </Button>
        </DialogActions>
      </div>
    </Dialog>
  )
}
