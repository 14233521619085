import { common } from "./common"
import { config } from "~/config"
import { authHeader } from "~/helpers/auth-header"

export const occurrenceService = {
  add_exception,
}

function add_exception(occurrence_id) {
  const requestOptions = {
    headers: authHeader(),
    method: "POST",
  }

  return fetch(config.serverAddress + "/occurrence/exceptions/" + occurrence_id, requestOptions)
    .then(common.handleResponse)
    .then(response => {
      return response
    })
}
