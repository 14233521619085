import React from "react"
import PatientTable from "./PatientTable"
import "./Patient.scss"

export default function PatientPage(props) {
  return (
    <div className="defaultContainer">
      <div className="title">
        <h1>Patients</h1>
      </div>
      <div className="patientTable">
        <PatientTable />
      </div>
    </div>
  )
}
