import Button from "@material-ui/core/Button"
import Checkbox from "@material-ui/core/Checkbox"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import InputAdornment from "@material-ui/core/InputAdornment"
import InputLabel from "@material-ui/core/InputLabel"
import MenuItem from "@material-ui/core/MenuItem"
import Select from "@material-ui/core/Select"
import TextField from "@material-ui/core/TextField"
import { useTheme } from "@material-ui/core/styles"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import React from "react"
import Calendar from "react-calendar"
import { ToastsContainer, ToastsStore, ToastsContainerPosition } from "react-toasts"
import CareNameSelect from "~/components/careEditor/careNameSelect"
import DurationTypeSelect from "~/components/careEditor/durationTypeSelect"

import "./CareEditor.scss"

export default function CareEditor(props) {
  console.log("in care editor", props.formState)

  const handleSubmit = () => {
    const [startDay, startMonth, startYear] = props.formState.startDate
      .toLocaleDateString()
      .split("/")
    const startDate_str = "" + startYear + "-" + startMonth + "-" + startDay

    const data = {
      date_start: startDate_str,
      evening: props.formState.evening,
      first_care: props.formState.firstCare,
      midday: props.formState.midday,
      morning: props.formState.morning,
      name: props.formState.careName,
      patient_id: props.patientId,
      type_frequency: props.formState.frequencyType,
    }

    if (data.name === "") {
      ToastsStore.error("Choisissez un soin")
      return
    }

    if (props.formState.specifyDuration) {
      data["duration"] = parseInt(props.formState.duration)
      if (isNaN(data["duration"])) {
        ToastsStore.error("Durée invalide")
        return
      }
    } else {
      const [endDay, endMonth, endYear] = props.formState.endDate.toLocaleDateString().split("/")
      const endDate_str = "" + endYear + "-" + endMonth + "-" + endDay
      if (endYear < startYear || endMonth < startMonth || endDay < startDay) {
        ToastsStore.error("Durée invalide")
        return
      }
      data["date_end"] = endDate_str
    }

    if (props.formState.frequencyType === "daily") {
      data["daily_details"] = {
        friday: props.formState.isFriday,
        monday: props.formState.isMonday,
        saturday: props.formState.isSaturday,
        sunday: props.formState.isSunday,
        thursday: props.formState.isThursday,
        tuesday: props.formState.isTuesday,
        wednesday: props.formState.isWednesday,
      }
    } else if (props.formState.frequencyType === "fixed_interval") {
      data["fixed_interval"] = props.formState.fixedInterval
    } else if (props.formState.frequencyType === "fixed_dates") {
      data["fixed_dates"] = props.formState.fixedDates.split(",")
    }

    props.addCare(data, () => {
      ToastsStore.success("Soin crée !")
      props.setEditorShown(false)

      props.resetForm()
    })
  }

  const handleClose = () => {
    props.setEditorShown(false)
    props.resetForm()
  }

  const handleCheckboxChange = name => event => {
    props.setFormState({ ...props.formState, [name]: event.target.checked })
  }

  const handleChange = name => value => {
    props.setFormState({ ...props.formState, [name]: value })
  }

  const handleEventChange = name => event => {
    props.setFormState({ ...props.formState, [name]: event.target.value })
  }

  const handleDateChange = name => date => {
    props.setFormState({ ...props.formState, [name]: date })
  }
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"))

  return (
    <Dialog
      aria-labelledby="scroll-dialog-title"
      fullScreen={fullScreen}
      maxWidth={fullScreen ? false : "sm"}
      open={props.editorShown}
      onClose={handleClose}
    >
      <div>
        <DialogTitle id="form-dialog-title">Ajouter un soin</DialogTitle>
        <DialogContent>
          <CareNameSelect set={handleChange("careName")} value={props.formState.careName} />
          <FormControlLabel
            control={
              <Checkbox
                checked={props.formState.firstCare}
                color="primary"
                onChange={handleCheckboxChange("firstCare")}
              />
            }
            label="Premier Soin"
          />
          <div className="dateContainer">
            <div className="beginDateContainer">
              <p>Date de début de soin :</p>
              <Calendar
                locale="fr"
                value={props.formState.startDate}
                onChange={handleDateChange("startDate")}
              />
            </div>
            <div className="dateEndContainer">
              <DurationTypeSelect
                default={props.formState.specifyDuration}
                set={handleChange("specifyDuration")}
                value={props.formState.specifyDuration}
              />
              {props.formState.specifyDuration ? (
                <TextField
                  InputProps={{
                    endAdornment: <InputAdornment position="end">jour(s)</InputAdornment>,
                  }}
                  label="Durée"
                  type="number"
                  value={props.formState.duration}
                  onChange={handleEventChange("duration")}
                />
              ) : (
                <Calendar
                  className="dateEndCalendar"
                  locale="fr"
                  value={props.formState.endDate}
                  onChange={handleDateChange("endDate")}
                />
              )}
            </div>
          </div>
          <FormControlLabel
            control={
              <Checkbox
                checked={props.formState.morning}
                color="primary"
                onChange={handleCheckboxChange("morning")}
              />
            }
            label="Matin"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={props.formState.midday}
                color="primary"
                onChange={handleCheckboxChange("midday")}
              />
            }
            label="Midi"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={props.formState.evening}
                color="primary"
                onChange={handleCheckboxChange("evening")}
              />
            }
            label="Soir"
          />

          <div className="verticalSpace"></div>
          <div className="frequencyContainer">
            <InputLabel>Frequence : </InputLabel>
            <Select
              value={props.formState.frequencyType}
              onChange={handleEventChange("frequencyType")}
            >
              <MenuItem value="daily">À des jours de la semaine</MenuItem>
              <MenuItem value="fixed_interval">À des intervalles réguliers </MenuItem>
              <MenuItem value="fixed_dates">À des dates fixes</MenuItem>
            </Select>
            {props.formState.frequencyType === "daily" ? (
              <>
                <div className="dailyFrequency">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={props.formState.isMonday}
                        color="primary"
                        onChange={handleCheckboxChange("isMonday")}
                      />
                    }
                    label="Lundi"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={props.formState.isTuesday}
                        color="primary"
                        onChange={handleCheckboxChange("isTuesday")}
                      />
                    }
                    label="Mardi"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={props.formState.isWednesday}
                        color="primary"
                        onChange={handleCheckboxChange("isWednesday")}
                      />
                    }
                    label="Mercredi"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={props.formState.isThursday}
                        color="primary"
                        onChange={handleCheckboxChange("isThursday")}
                      />
                    }
                    label="Jeudi"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={props.formState.isFriday}
                        color="primary"
                        onChange={handleCheckboxChange("isFriday")}
                      />
                    }
                    label="Vendredi"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={props.formState.isSaturday}
                        color="primary"
                        onChange={handleCheckboxChange("isSaturday")}
                      />
                    }
                    label="Samedi"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={props.formState.isSunday}
                        color="primary"
                        onChange={handleCheckboxChange("isSunday")}
                      />
                    }
                    label="Dimanche"
                  />
                </div>
              </>
            ) : props.formState.frequencyType === "fixed_interval" ? (
              <TextField
                InputProps={{
                  endAdornment: <InputAdornment position="end">jour(s)</InputAdornment>,
                }}
                label="Tous les "
                type="number"
                value={props.formState.fixedInterval}
                onChange={handleEventChange("fixedInterval")}
              />
            ) : props.formState.frequencyType === "fixed_dates" ? (
              <TextField
                label="Dates (AAAA-MM-DD,AAAA-MM-DD,...)"
                value={props.formState.fixedDates}
                onChange={handleEventChange("fixedDates")}
              />
            ) : null}
          </div>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={handleClose}>
            Annuler
          </Button>
          <Button color="primary" onClick={handleSubmit}>
            Ajouter
          </Button>
        </DialogActions>
      </div>
      <ToastsContainer position={ToastsContainerPosition.BOTTOM_CENTER} store={ToastsStore} />
    </Dialog>
  )
}
