import MaterialTable from "material-table"
import React, { useContext, forwardRef } from "react"
import ClipLoader from "react-spinners/ClipLoader"
import AddIcon from "~/components/icons/AddSVG"
import DeleteIcon from "~/components/icons/DeleteSVG"
import EditIcon from "~/components/icons/EditSVG"
import { UserContext } from "~/helpers/context"
import { usePatients, useReloadPatients } from "~/hooks/api"
import { patientService } from "~/services/patient"

const PatientTable = () => {
  const userContext = useContext(UserContext)

  const { isLoading, error, data: patientList } = usePatients()
  const reloadPatients = useReloadPatients()

  if (error) {
    return <p>{error.message}</p>
  }

  if (isLoading) {
    return (
      <div className="loader">
        <ClipLoader color={"#6B6F8F"} size={100} />
      </div>
    )
  }

  const onRowAdd = newData =>
    new Promise((resolve, reject) => {
      patientService.create(newData).then(async () => {
        await reloadPatients()
        resolve()
      })
    })

  const onRowUpdate = (newData, oldData) =>
    new Promise((resolve, reject) => {
      patientService.update(newData).then(async result => {
        await reloadPatients()
        resolve()
      })
    })

  const onRowDelete = oldData =>
    new Promise((resolve, reject) => {
      patientService.remove(oldData.patient_id).then(async () => {
        await reloadPatients()
        resolve()
      })
    })

  /* eslint-disable react/display-name */
  const tableIcons = {
    Add: forwardRef((props, ref) => <AddIcon {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteIcon {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <EditIcon {...props} ref={ref} />),
  }
  /* eslint-enable react/display-name */

  return (
    <MaterialTable
      columns={[
        {
          field: "civility",
          initialEditValue: "M.",
          lookup: { "M.": "M.", Mle: "Mle", Mme: "Mme" },
          title: "Civilité",
        },
        { field: "name", title: "Nom" },
        { field: "firstname", title: "Prénom" },
        { field: "address", title: "Adresse" },
        { field: "suspended", title: "Suspendu?", type: "boolean" },
      ]}
      data={patientList}
      editable={{
        onRowAdd,
        onRowDelete: userContext.values.role === "admin" ? onRowDelete : null,
        onRowUpdate,
      }}
      icons={tableIcons}
      localization={{
        body: {
          addTooltip: "Ajouter",
          deleteTooltip: "Supprimer",
          editRow: {
            cancelTooltip: "Annuler",
            deleteText: "Êtes-vous sur de vouloir supprimer ce patient ? ",
            saveTooltip: "Sauver",
          },
          editTooltip: "Modifier",
          emptyDataSourceMessage: "Aucune donnée à afficher",
        },
        pagination: {
          firstTooltip: "Première page",
          labelDisplayedRows: "{from}-{to} sur {count}",
          labelRowsSelect: "lignes",
          lastTooltip: "Dernière page",
          nextTooltip: "Suivant",
          previousTooltip: "Précédent",
        },
        toolbar: {
          searchPlaceholder: "Rechercher",
        },
      }}
      options={{
        actionsColumnIndex: -1,
        showTitle: false,
      }}
    />
  )
}

export default PatientTable
