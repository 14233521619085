import SvgIcon from "@material-ui/core/SvgIcon"
import React from "react"

const PrintIcon = props => (
  <SvgIcon className="printIconSvg" viewBox="0 0 36 36">
    <path d="M31.5,13.5V5.432a2.251,2.251,0,0,0-.659-1.591L27.659.659A2.25,2.25,0,0,0,26.068,0H6.75A2.25,2.25,0,0,0,4.5,2.25V13.5A4.5,4.5,0,0,0,0,18v7.875A1.125,1.125,0,0,0,1.125,27H4.5v6.75A2.25,2.25,0,0,0,6.75,36h22.5a2.25,2.25,0,0,0,2.25-2.25V27h3.375A1.125,1.125,0,0,0,36,25.875V18A4.5,4.5,0,0,0,31.5,13.5ZM27,31.5H9V24.75H27Zm0-15.75H9V4.5H22.5V7.875A1.125,1.125,0,0,0,23.625,9H27Zm3.375,5.063a1.688,1.688,0,1,1,1.688-1.687A1.688,1.688,0,0,1,30.375,20.813Z" />
  </SvgIcon>
)

export default PrintIcon
