import React, { useContext } from "react"
import { NursesContext } from "~/helpers/context"

function TotalDay(props) {
  const nursesContext = useContext(NursesContext)

  if (!nursesContext.values.nursesList) {
    return null
  }

  // Key/value to Convert nurse_id to nurse_name
  const nurse_names = {}
  nursesContext.values.nursesList.forEach(nurse => {
    nurse_names[nurse.nurse_id] = nurse.name
  })

  const nurses = {}
  let total_cares = 0
  props.patient_list.forEach(patient => {
    total_cares += 1

    for (let i = 0; i < patient.list_cares.length; i++) {
      const nurse_id = patient.list_cares[i].attribution
      if (nurse_id) {
        const nurse_name = nurse_names[nurse_id]
        if (nurse_name in nurses) {
          nurses[nurse_name] += 1
        } else {
          nurses[nurse_name] = 1
        }
        break
      }
    }
  })

  return (
    <div className={`totalCares ${props.printable ? "" : "isBig"}`}>
      <b>Total des soins</b>
      <div className={props.printable ? "" : "totalCaresBigNumber"}>{total_cares}</div>
      {Object.entries(nurses).map(([key, value]) => (
        <p key={key}>
          <b>{key} :</b> {value}
        </p>
      ))}
    </div>
  )
}

export default TotalDay
