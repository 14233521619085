import { common } from "./common"
import { config } from "~/config"
import { authHeader } from "~/helpers/auth-header"

export const userService = {
  create,
  get,
  remove,
  update,
}

function get() {
  const requestOptions = {
    headers: authHeader(),
    method: "GET",
  }

  return fetch(config.serverAddress + "/users", requestOptions)
    .then(common.handleResponse)
    .then(user => {
      return user.users
    })
}

function create(new_user) {
  const requestOptions = {
    body: JSON.stringify(new_user),
    headers: { "Content-Type": "application/json", ...authHeader() },
    method: "POST",
  }

  return fetch(config.serverAddress + "/users", requestOptions).then(response => {
    return response
  })
}

function update(existing_user) {
  const requestOptions = {
    body: JSON.stringify(existing_user),
    headers: { "Content-Type": "application/json", ...authHeader() },
    method: "PUT",
  }

  return fetch(config.serverAddress + "/user/" + existing_user["id"], requestOptions).then(
    response => {
      return response
    }
  )
}

function remove(user_id) {
  const requestOptions = {
    headers: { "Content-Type": "application/json", ...authHeader() },
    method: "DELETE",
  }

  return fetch(config.serverAddress + "/user/" + user_id, requestOptions).then(response => {
    return response
  })
}
