import IconButton from "@material-ui/core/IconButton"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableRow from "@material-ui/core/TableRow"
import HighlightOffIcon from "@material-ui/icons/HighlightOff"
import React from "react"
import NurseSelect from "~/components/NurseSelect"
import { CARES } from "~/const"
import firstCareIcon from "~/resources/icons/firstIcon.png"
import lastCareIcon from "~/resources/icons/lastIcon.png"

const colorKeys = {}
CARES.forEach(element => {
  colorKeys[element.label] = element.color
})

function CareRenderer(props) {
  const setNurse = careIndex => nurseId => {
    props.changeAttribution(props.dayPart, props.patientIndex, careIndex, nurseId)
  }

  const addException = () => {
    props.addException(props.element.occurrence_id)
  }

  return (
    <div style={{ display: "flex" }}>
      <NurseSelect
        careIndex={props.index}
        printable={props.printable}
        setNurse={setNurse(props.index)}
        value={props.element.attribution}
      />
      {!props.printable && (
        <IconButton id="deleteButton" onClick={addException}>
          <HighlightOffIcon />
        </IconButton>
      )}
    </div>
  )
}

function PatientRenderer(props) {
  const myCode = []
  let index = 0
  props.element.list_cares.forEach(care => {
    const first_care_icon = care.first_care ? (
      <img alt="first care" className="flexImg" src={firstCareIcon} />
    ) : null
    const last_care_icon = care.last_care ? (
      <img alt="last care" className="flexImg" src={lastCareIcon} />
    ) : null
    let attribution_view = null
    if (!props.personal) {
      attribution_view = (
        <TableCell>
          <CareRenderer
            addException={props.addException}
            changeAttribution={props.changeAttribution}
            dayPart={props.dayPart}
            element={care}
            index={index}
            patientIndex={props.patientIndex}
            printable={props.printable}
          />
        </TableCell>
      )
    }

    if (index === 0) {
      myCode.push(
        <TableRow key={index}>
          <TableCell className="patientName" title={props.element.infobulle}>
            {props.element.patient}
          </TableCell>
          <TableCell>
            <div className="flex">
              {first_care_icon}
              {last_care_icon}
              <p className="careName" style={{ color: colorKeys[care.name] }}>
                {care.name}
              </p>
            </div>
          </TableCell>
          {attribution_view}
        </TableRow>
      )
    } else {
      myCode.push(
        <TableRow key={index}>
          <TableCell></TableCell>
          <TableCell>
            <div className="flex">
              {first_care_icon}
              {last_care_icon}
              <p style={{ color: colorKeys[care.name] }}>{care.name}</p>
            </div>
          </TableCell>
          {attribution_view}
        </TableRow>
      )
    }
    index++
  })
  return myCode
}

export default function CalendarPatientView(props) {
  const data = []
  props.patient_list.forEach(patient => {
    data.push({
      infobulle: patient.patient_address,
      list_cares: patient.list_cares,
      patient: patient.civility + " " + patient.patient_firstname + " " + patient.patient_name,
      patient_id: patient.patient_id,
    })
  })

  return (
    <Table>
      <TableBody className={`patientCaresTable ${props.printable ? "printable" : ""}`}>
        {data.map((element, index) => (
          <PatientRenderer
            key={index}
            addException={props.addException}
            changeAttribution={props.changeAttribution}
            dayPart={props.dayPart}
            element={element}
            patientIndex={index}
            personal={props.personal}
            printable={props.printable}
          />
        ))}
      </TableBody>
    </Table>
  )
}
