import React from "react"

export const UserContext = React.createContext({
  role: null,
  username: "Guest",
})

export const NursesContext = React.createContext({
  nurseList: [],
})
