import React from "react"
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom"
import Menu from "~/Menu/Menu"
import { CalendarPage } from "~/Pages/CalendarPage/CalendarPage"
import CarePage from "~/Pages/CarePage/CarePage"
import { LoginPage } from "~/Pages/LoginPage/LoginPage"
import MonthPage from "~/Pages/MonthPage/MonthPage"
import PatientPage from "~/Pages/PatientPage/PatientPage"
import PersonalCalendarPage from "~/Pages/PersonalCalendarPage/PersonalCalendarPage"
import SettingsPage from "~/Pages/SettingsPage"
import UserAdminPage from "~/Pages/UserAdminPage/UserAdminPage"
import { PrivateRoute } from "~/components/PrivateRoute"
import SimpleSnackBar from "~/components/SimpleSnackBar"
import { UserContext, NursesContext } from "~/helpers/context"
import { nurseService } from "~/services/nurse"
import { patientService } from "~/services/patient"

function DefaultRoute() {
  return <Redirect to="/calendar" />
}

class App extends React.Component {
  setUserContext = userContextValues => {
    this.setState({
      userContext: {
        setter: this.setUserContext,
        values: userContextValues,
      },
    })
  }

  setNurseContext = nursesContextValues => {
    this.setState({
      nursesContext: {
        setter: this.setNurseContext,
        values: nursesContextValues,
      },
    })
  }

  setPatientContext = patientsContextValues => {
    this.setState({
      patientsContext: {
        setter: this.setPatientContext,
        values: patientsContextValues,
      },
    })
  }

  setSnackBarOpen = value => {
    this.setState({
      snackBarOpen: value,
    })
  }

  fetchResources = () => {
    nurseService.get().then(nurse_list => {
      this.setNurseContext({
        nursesList: nurse_list,
      })
    })

    patientService.get().then(patient_list => {
      this.setPatientContext({
        patientsList: patient_list,
      })
    })
  }

  constructor() {
    super()

    const persitentUser = JSON.parse(localStorage.getItem("user"))
    let userContextValues
    let showSnackBar = false
    if (persitentUser) {
      this.username = persitentUser.username
      userContextValues = {
        loggedIn: true,
        nurseId: persitentUser.nurse_id,
        role: persitentUser.role,
        username: persitentUser.username,
      }
      showSnackBar = true

      this.fetchResources()
    } else {
      userContextValues = {
        loggedIn: false,
        nurseId: null,
        role: null,
        username: "Guest",
      }
    }

    const nursesContextValues = {
      nurseList: [],
    }
    const patientsContextValues = {
      patientsList: [],
    }

    this.state = {
      nursesContext: {
        setter: this.setNurseContext,
        values: nursesContextValues,
      },
      patientsContext: {
        setter: this.setPatientContext,
        values: patientsContextValues,
      },
      snackBarOpen: showSnackBar,
      userContext: {
        setter: this.setUserContext,
        values: userContextValues,
      },
    }
  }

  render() {
    return (
      <UserContext.Provider value={this.state.userContext}>
        <NursesContext.Provider value={this.state.nursesContext}>
          <div>
            <div>
              <BrowserRouter basename="/frontend">
                <Menu />
                <Switch>
                  <PrivateRoute component={UserAdminPage} exact path="/managerUser" />
                  <PrivateRoute component={CalendarPage} exact path="/calendar" />
                  <PrivateRoute component={PersonalCalendarPage} exact path="/personalcalendar" />
                  <PrivateRoute component={PatientPage} exact path="/patient" />
                  <PrivateRoute component={CarePage} exact path="/care" />
                  <PrivateRoute component={MonthPage} exact path="/month" />
                  <PrivateRoute component={SettingsPage} exact path="/settings" />
                  <Route
                    path="/login"
                    render={props => <LoginPage {...props} onLogin={this.fetchResources} />}
                  />
                  <PrivateRoute component={DefaultRoute} path="/" />
                </Switch>
              </BrowserRouter>
            </div>
          </div>
          <SimpleSnackBar
            open={this.state.snackBarOpen}
            setOpen={this.setSnackBarOpen}
            username={this.username}
          />
        </NursesContext.Provider>
      </UserContext.Provider>
    )
  }
}

export default App
