import { Accordion } from "@material-ui/core"
import AccordionDetails from "@material-ui/core/AccordionDetails"
import AccordionSummary from "@material-ui/core/AccordionSummary"
import Typography from "@material-ui/core/Typography"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import React from "react"
import { useMediaQuery } from "react-responsive"
import CalendarPatientView from "../CalendarPage/CalendarPatientView"
import TotalDay from "../CalendarPage/TotalDay"
import eveningIcon from "~/resources/icons/evening.svg"
import middayIcon from "~/resources/icons/midday.svg"
import morningIcon from "~/resources/icons/morning.svg"

export default function GenericCalendarPage(props) {
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-device-width: 1224px)",
  })

  const [expandedMorning, setExpandedMorning] = React.useState(isDesktopOrLaptop)
  const [expandedMidday, setExpandedMidday] = React.useState(isDesktopOrLaptop)
  const [expandedEvening, setExpandedEvening] = React.useState(isDesktopOrLaptop)

  const morning = props.cares?.morning
  const midday = props.cares?.midday
  const evening = props.cares?.evening

  const handleChangeMorning = (event, isExpanded) => {
    setExpandedMorning(isExpanded)
  }

  const handleChangeMidday = (event, isExpanded) => {
    setExpandedMidday(isExpanded)
  }

  const handleChangeEvening = (event, isExpanded) => {
    setExpandedEvening(isExpanded)
  }

  return (
    <div className="calendarContainer">
      <div className="columnContainer">
        <div className="column">
          <Accordion expanded={expandedMorning} onChange={handleChangeMorning}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography component="div">
                <div className="columnHeader">
                  {!isDesktopOrLaptop && <div className="mobileTotalCares">{morning.length}</div>}
                  <h2>
                    {" "}
                    <img alt="morning_icon" src={morningIcon} /> Matin
                  </h2>
                </div>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography component="div">
                {morning && morning.length > 0 && (
                  <CalendarPatientView
                    addException={props.addException}
                    changeAttribution={props.changeAttribution}
                    dayPart="morning"
                    patient_list={morning}
                    personal={props.personal}
                    printable={props.printable}
                  />
                )}
                <TotalDay
                  patient_list={morning}
                  personal={props.personal}
                  printable={props.printable}
                />
              </Typography>
            </AccordionDetails>
          </Accordion>
        </div>
        {!isDesktopOrLaptop && <div className="mobileSpace" />}
        <div className="column">
          <Accordion expanded={expandedMidday} onChange={handleChangeMidday}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography component="div">
                <div className="columnHeader">
                  {!isDesktopOrLaptop && <div className="mobileTotalCares">{midday.length}</div>}
                  <h2>
                    {" "}
                    <img alt="midday icon" src={middayIcon} />
                    Midi
                  </h2>
                </div>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography component="div">
                {midday && midday.length > 0 && (
                  <CalendarPatientView
                    addException={props.addException}
                    changeAttribution={props.changeAttribution}
                    dayPart="midday"
                    patient_list={midday}
                    personal={props.personal}
                    printable={props.printable}
                  />
                )}
                <TotalDay
                  patient_list={midday}
                  personal={props.personal}
                  printable={props.printable}
                />
              </Typography>
            </AccordionDetails>
          </Accordion>
        </div>
        {!isDesktopOrLaptop && <div className="mobileSpace" />}
        <div className="column">
          <Accordion expanded={expandedEvening} onChange={handleChangeEvening}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography component="div">
                <div className="columnHeader">
                  {!isDesktopOrLaptop && <div className="mobileTotalCares">{evening.length}</div>}
                  <h2>
                    <img alt="evening icon" src={eveningIcon} /> Soir
                  </h2>
                </div>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography component="div">
                {evening && evening.length > 0 && (
                  <CalendarPatientView
                    addException={props.addException}
                    changeAttribution={props.changeAttribution}
                    dayPart="evening"
                    patient_list={evening}
                    personal={props.personal}
                    printable={props.printable}
                  />
                )}
                <TotalDay
                  patient_list={evening}
                  personal={props.personal}
                  printable={props.printable}
                />
              </Typography>
            </AccordionDetails>
          </Accordion>
        </div>
      </div>
    </div>
  )
}
