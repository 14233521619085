import React from "react"
import CareComponent from "./CareComponent"

export default function CarePage() {
  return (
    <div className="defaultContainer">
      <div className="title">
        <h1>Soins</h1>
      </div>
      <CareComponent />
    </div>
  )
}
