import { common } from "./common"
import { config } from "~/config"
import { authHeader } from "~/helpers/auth-header"

export const noteService = {
  create,
  get,
  remove,
}

function create(data) {
  const requestOptions = {
    body: JSON.stringify(data),
    headers: { "Content-Type": "application/json", ...authHeader() },
    method: "POST",
  }

  return fetch(config.serverAddress + "/notes", requestOptions)
    .then(common.handleResponse)
    .then(response => {
      return response.note_id
    })
}

function get(date) {
  const requestOptions = {
    headers: authHeader(),
    method: "GET",
  }

  return fetch(config.serverAddress + "/notes/" + date, requestOptions)
    .then(common.handleResponse)
    .then(reply => {
      return reply.notes
    })
}

function remove(note_id) {
  const requestOptions = {
    headers: authHeader(),
    method: "DELETE",
  }

  return fetch(config.serverAddress + "/notes/" + note_id, requestOptions).then(care => {
    return care
  })
}
