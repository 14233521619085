import React from "react"
import Select from "react-select"

export default function DurationTypeSelect(props) {
  const handleChange = event => {
    props.set(event.value === "duration")
  }

  const options = [
    { label: "Durée fixe", value: "duration" },
    { label: "Date de fin", value: "date" },
  ]

  let defaultValue = null
  if (props.default === true) {
    defaultValue = options[0]
  } else if (props.default === false) {
    defaultValue = options[1]
  }

  return <Select defaultValue={defaultValue} options={options} onChange={handleChange} />
}
