import { common } from "./common"
import { config } from "~/config"
import { authHeader } from "~/helpers/auth-header"

export const nurseService = {
  create,
  get,
  remove,
  update,
}

function get() {
  const requestOptions = {
    headers: authHeader(),
    method: "GET",
  }

  return fetch(config.serverAddress + "/nurses", requestOptions)
    .then(common.handleResponse)
    .then(nurse => {
      return nurse.nurses
    })
}

function create(new_nurse) {
  const requestOptions = {
    body: JSON.stringify(new_nurse),
    headers: { "Content-Type": "application/json", ...authHeader() },
    method: "POST",
  }

  return fetch(config.serverAddress + "/nurses", requestOptions).then(response => {
    return response
  })
}

function update(existing_user) {
  const requestOptions = {
    body: JSON.stringify(existing_user),
    headers: { "Content-Type": "application/json", ...authHeader() },
    method: "PUT",
  }

  return fetch(config.serverAddress + "/nurse/" + existing_user["nurse_id"], requestOptions).then(
    response => {
      return response
    }
  )
}

function remove(nurse_id) {
  const requestOptions = {
    headers: { "Content-Type": "application/json", ...authHeader() },
    method: "DELETE",
  }

  return fetch(config.serverAddress + "/nurse/" + nurse_id, requestOptions).then(response => {
    return response
  })
}
