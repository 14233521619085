import React from "react"
import Select from "react-select"

const selectStyles = { menu: styles => ({ ...styles, zIndex: 999 }) }

export default function PatientSelect(props) {
  const handleChange = event => {
    if (event) {
      props.setSelectedPatient(event.value)
    } else {
      props.setSelectedPatient(null)
    }
  }

  const patient_list = []
  props.patients.forEach(patient => {
    const label =
      "" + patient.civility + " " + patient.name + " " + patient.firstname + " - " + patient.address
    patient_list.push({ label, suspended: patient.suspended, value: patient.patient_id })
  })

  return (
    <Select
      isClearable
      isOptionDisabled={option => option.suspended}
      isSearchable
      options={patient_list}
      placeholder="Choisir un patient"
      styles={selectStyles}
      onChange={handleChange}
    />
  )
}
