import Button from "@material-ui/core/Button"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import { useTheme } from "@material-ui/core/styles"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import React from "react"
import Calendar from "react-calendar"
import { ToastsContainer, ToastsStore, ToastsContainerPosition } from "react-toasts"
import { careService } from "~/services/care"

import "./CareEditor.scss"

export default function CareSuspension(props) {
  const data = {
    care: props.care,
    patient_id: props.patientId,
  }

  const [date, setDate] = React.useState(new Date())

  const handleSubmit = () => {
    const [day, month, year] = date.toLocaleDateString().split("/")
    const date_str = "" + year + "-" + month + "-" + day

    careService.setSuspended(props.care.care_id, date_str, !isSuspended).then(result => {
      handleClose()
    })
  }

  const handleClose = () => {
    props.setSuspendedShown(false)
    props.refresh()
  }

  const handleDateChange = date => {
    setDate(date)
  }

  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"))

  if (!data.care) return null

  const isSuspended = Boolean(data.care.suspension)

  return (
    <Dialog
      aria-labelledby="form-dialog-title"
      fullScreen={fullScreen}
      maxWidth={fullScreen ? false : "sm"}
      open={props.suspendedShown}
      onClose={handleClose}
    >
      <div className="addCareContainer">
        <DialogTitle id="form-dialog-title">
          {isSuspended ? "Dé-suspendre un soin" : "Suspendre un soin"}
        </DialogTitle>
        <DialogContent>
          {!isSuspended && <p>Date de début de suspension :</p>}
          {isSuspended && <p>Date de fin de suspension :</p>}
          <div className="suspensionCalendarContainer">
            <Calendar locale="fr" value={date} onChange={handleDateChange} />
          </div>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={handleClose}>
            Annuler
          </Button>
          <Button color="primary" onClick={handleSubmit}>
            Valider
          </Button>
        </DialogActions>
      </div>
      <ToastsContainer position={ToastsContainerPosition.BOTTOM_CENTER} store={ToastsStore} />
    </Dialog>
  )
}
