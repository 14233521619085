import React from "react"
import Select from "react-select"
import { CARES } from "~/const"

CARES.forEach(element => {
  element.value = element.label
})

export default function CareNameSelect(props) {
  const handleChange = event => {
    props.set(event.value)
  }

  const colourStyles = {
    control: styles => ({
      ...styles,
      backgroundColor: "white",
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        color: data.color,
      }
    },
  }
  const selectedValue = CARES.filter(e => e.value === props.value)
  return (
    <Select
      label="Name"
      options={CARES}
      placeholder="Choisir un soin"
      styles={colourStyles}
      value={selectedValue}
      onChange={handleChange}
    />
  )
}
