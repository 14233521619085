function handleResponse(response) {
  return response.text().then(text => {
    let data = null
    try {
      data = text && JSON.parse(text)
    } catch (e) {
      console.error("Parsing error:", e)
      return Promise.reject("Unable to parse the response")
    }

    if (!response.ok) {
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        localStorage.removeItem("user")
        //location.reload(true);
      }

      const error = (data && data.message) || response.statusText
      return Promise.reject(error)
    }

    return data
  })
}

export const common = {
  handleResponse,
}
