import MaterialTable from "material-table"
import React, { useContext, forwardRef } from "react"
import CareEditor from "~/components/CareEditor"
import CareSuspension from "~/components/CareSuspension"
import AddIcon from "~/components/icons/AddSVG"
import CustomCancelScheduleSendIcon from "~/components/icons/CustomCancelScheduleSendIcon"
import CustomSendIcon from "~/components/icons/CustomSendIcon"
import DeleteIcon from "~/components/icons/DeleteSVG"
import DuplicateIcon from "~/components/icons/DuplicateIcon"
import { UserContext } from "~/helpers/context"
import { careService } from "~/services/care"

import "./CareTable.scss"

export default function CareTable(props) {
  const userContext = useContext(UserContext)
  const [editorShown, setEditorShown] = React.useState(false)
  const [suspendedShown, setSuspendedShown] = React.useState(false)
  const [care, setCare] = React.useState(null)

  const [careEditorState, setCareEditorState] = React.useState({
    careName: "",
    duration: "",
    endDate: new Date(),
    evening: true,
    firstCare: true,
    fixedDates: "",
    fixedInterval: "",
    frequencyType: "daily",
    isFriday: true,
    isMonday: true,
    isSaturday: true,
    isSunday: true,
    isThursday: true,
    isTuesday: true,
    isWednesday: true,
    midday: true,
    morning: true,
    specifyDuration: true,
    startDate: new Date(),
  })

  const prepareForm = selectedCare => {
    console.log(" in caretable ", selectedCare)
    setCareEditorState({
      careName: selectedCare.name,
      duration: selectedCare.duration,
      endDate: new Date(),
      evening: selectedCare.evening,
      firstCare: selectedCare.first_care,
      fixedDates: "",
      fixedInterval: selectedCare.fixed_interval,
      frequencyType: selectedCare.type_frequency,
      isFriday: selectedCare.daily_details?.friday,
      isMonday: selectedCare.daily_details?.monday,
      isSaturday: selectedCare.daily_details?.saturday,
      isSunday: selectedCare.daily_details?.sunday,
      isThursday: selectedCare.daily_details?.thursday,
      isTuesday: selectedCare.daily_details?.tuesday,
      isWednesday: selectedCare.daily_details?.wednesday,
      midday: selectedCare.midday,
      morning: selectedCare.morning,
      specifyDuration: selectedCare.duration ? true : false,
      startDate: new Date(),
    })
  }

  const resetForm = () => {
    setCareEditorState({
      careName: "",
      duration: "",
      endDate: new Date(),
      evening: true,
      firstCare: true,
      fixedDates: "",
      fixedInterval: "",
      frequencyType: "daily",
      isFriday: true,
      isMonday: true,
      isSaturday: true,
      isSunday: true,
      isThursday: true,
      isTuesday: true,
      isWednesday: true,
      midday: true,
      morning: true,
      specifyDuration: true,
      startDate: new Date(),
    })
  }

  const onRowDelete = oldData =>
    new Promise((resolve, reject) => {
      careService.remove(oldData.care_id).then(() => {
        props.setCares(props.cares.filter(element => element.care_id !== oldData.care_id))
        resolve()
      })
    })

  /* eslint-disable react/display-name */
  const tableIcons = {
    Add: forwardRef((props, ref) => <AddIcon {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteIcon {...props} ref={ref} />),
  }
  /* eslint-enable react/display-name */

  const timeRenderer = value => {
    if (!value) {
      return "-"
    }
    const [year, month, day] = value.split("-")
    return `${day}/${month}/${year}`
  }

  return (
    <>
      <MaterialTable
        actions={[
          {
            icon: AddIcon,
            isFreeAction: true,
            onClick: (event, rowData) => {
              setCare(undefined)
              setEditorShown(true)
            },
            tooltip: "Ajouter un soin",
          },
          rowData => ({
            icon: rowData.suspension ? CustomSendIcon : CustomCancelScheduleSendIcon,
            onClick: (event, rowData) => {
              setCare(rowData)
              setSuspendedShown(true)
            },
            tooltip: rowData.suspension ? "Dé-suspendre le soin" : "Suspendre le soin",
          }),
          rowData => ({
            icon: DuplicateIcon,
            onClick: (event, rowData) => {
              prepareForm(rowData)
              setEditorShown(true)
            },
            tooltip: "Dupliquer le soin",
          }),
        ]}
        columns={[
          { field: "name", title: "Nom" },
          {
            field: "date_start",
            render: row => timeRenderer(row["date_start"]),
            title: "Date de début",
          },
          {
            field: "suspension",
            render: row => timeRenderer(row["suspension"]),
            title: "Date suspension",
          },
        ]}
        data={props.cares}
        editable={{
          onRowAdd: null,
          onRowDelete: userContext.values.role === "admin" ? onRowDelete : null,
          onRowUpdate: null,
        }}
        icons={tableIcons}
        localization={{
          body: {
            addTooltip: "Ajouter",
            deleteTooltip: "Supprimer",
            editRow: {
              cancelTooltip: "Annuler",
              deleteText: "Êtes-vous sur de vouloir supprimer ce soin ? ",
              saveTooltip: "Sauver",
            },
            editTooltip: "Modifier",
            emptyDataSourceMessage: "Aucune donnée à afficher",
          },
          pagination: {
            firstTooltip: "Première page",
            labelDisplayedRows: "{from}-{to} sur {count}",
            labelRowsSelect: "lignes",
            lastTooltip: "Dernière page",
            nextTooltip: "Suivant",
            previousTooltip: "Précédent",
          },
          toolbar: {
            searchPlaceholder: "Rechercher",
          },
        }}
        options={{
          actionsColumnIndex: -1,
          showTitle: false,
        }}
        title="Soins"
      />
      <CareEditor
        addCare={props.addCare}
        editorShown={editorShown}
        formState={careEditorState}
        patientId={props.patientId}
        resetForm={resetForm}
        setEditorShown={setEditorShown}
        setFormState={setCareEditorState}
      />
      <CareSuspension
        care={care}
        patientId={props.patientId}
        refresh={props.refresh}
        setSuspendedShown={setSuspendedShown}
        suspendedShown={suspendedShown}
      />
    </>
  )
}
