import { common } from "./common"
import { config } from "~/config"
import { authHeader } from "~/helpers/auth-header"

export const careService = {
  assign,
  create,
  get_for_day,
  get_personal_for_day,
  get_total,
  remove,
  setSuspended,
}

function get_for_day(date) {
  const requestOptions = {
    headers: authHeader(),
    method: "GET",
  }

  return fetch(config.serverAddress + "/cares/" + date, requestOptions)
    .then(common.handleResponse)
    .then(care => {
      return care.cares
    })
}

function get_personal_for_day(nurse_id, date) {
  const requestOptions = {
    headers: authHeader(),
    method: "GET",
  }

  return fetch(config.serverAddress + "/cares/" + date + "/" + nurse_id, requestOptions)
    .then(common.handleResponse)
    .then(care => {
      return care.cares
    })
}

function get_total(nurse_id, month) {
  const requestOptions = {
    headers: authHeader(),
    method: "GET",
  }

  return fetch(config.serverAddress + "/total/" + month + "/" + nurse_id, requestOptions)
    .then(common.handleResponse)
    .then(result => {
      return result.total
    })
}

function assign(occurenceId, dayPart, attribution) {
  const body = {
    attribution,
    when_day: dayPart,
  }

  const requestOptions = {
    body: JSON.stringify(body),
    headers: { "Content-Type": "application/json", ...authHeader() },
    method: "PUT",
  }

  return fetch(config.serverAddress + "/occurrence/attribute/" + occurenceId, requestOptions).then(
    care => {
      return care
    }
  )
}

function remove(careId) {
  const requestOptions = {
    headers: authHeader(),
    method: "DELETE",
  }

  return fetch(config.serverAddress + "/care/" + careId, requestOptions).then(care => {
    return care
  })
}

function create(data) {
  const requestOptions = {
    body: JSON.stringify(data),
    headers: { "Content-Type": "application/json", ...authHeader() },
    method: "POST",
  }

  return fetch(config.serverAddress + "/cares", requestOptions)
    .then(common.handleResponse)
    .then(response => {
      return response.care
    })
}

function setSuspended(careId, date, isSuspended) {
  const requestOptions = {
    headers: authHeader(),
    method: isSuspended ? "POST" : "DELETE",
  }

  return fetch(config.serverAddress + "/suspension/care/" + date + "/" + careId, requestOptions)
    .then(common.handleResponse)
    .then(result => {
      return result
    })
}
