import React from "react"
import { UserContext } from "~/helpers/context"
import { loginService } from "~/services/authentication"
import "./LoginPage.scss"

class LoginPage extends React.Component {
  static contextType = UserContext

  constructor(props) {
    super(props)

    this.state = {
      error: "",
      loading: false,
      password: "",
      submitted: false,
      username: "",
    }

    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  componentDidMount() {
    const userContext = this.context

    loginService.logout()

    userContext.setter({
      loggedIn: false,
      nurseId: null,
      role: null,
      username: "Guest",
    })
  }

  handleChange(e) {
    const { name, value } = e.target
    this.setState({ [name]: value })
  }

  handleSubmit(e) {
    e.preventDefault()

    this.setState({ submitted: true })
    const { username, password } = this.state

    // stop here if form is invalid
    if (!(username && password)) {
      return
    }

    this.setState({ loading: true })
    loginService.login(username, password).then(
      user => {
        const userContext = this.context
        userContext.setter({
          loggedIn: true,
          nurseId: user.nurse_id,
          role: user.role,
          username: user.username,
        })

        this.props.onLogin()

        const { from } = this.props.location.state || { from: { pathname: "/" } }
        this.props.history.push(from)
      },
      error => {
        this.setState({ error: error.message, loading: false })
      }
    )
  }

  render() {
    const { username, password, submitted, loading, error } = this.state
    return (
      <div className="login">
        <h2>Login</h2>
        <form name="form" onSubmit={this.handleSubmit}>
          <div className={"form-group" + (submitted && !username ? " has-error" : "")}>
            <label htmlFor="username">Username</label>
            <input
              className="form-control"
              name="username"
              type="text"
              value={username}
              onChange={this.handleChange}
            />
            {submitted && !username && <div className="help-block">Username is required</div>}
          </div>
          <div className={"form-group" + (submitted && !password ? " has-error" : "")}>
            <label htmlFor="password">Password</label>
            <input
              className="form-control"
              name="password"
              type="password"
              value={password}
              onChange={this.handleChange}
            />
            {submitted && !password && <div className="help-block">Password is required</div>}
          </div>
          <div className="form-group">
            <button className="btn btn-primary" disabled={loading}>
              Login
            </button>
            {loading && (
              <img
                alt="loading"
                src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=="
              />
            )}
          </div>
          {error && <div className="alert alert-danger">{error}</div>}
        </form>
      </div>
    )
  }
}

export { LoginPage }
