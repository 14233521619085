import Button from "@material-ui/core/Button"
import IconButton from "@material-ui/core/IconButton"
import React, { useState, useRef } from "react"
import Calendar from "react-calendar"
import { useMediaQuery } from "react-responsive"
import ClipLoader from "react-spinners/ClipLoader"
import { useReactToPrint } from "react-to-print"
import GenericCalendarPage from "../GenericCalendar/GenericCalendarPage"
import NoteEditor from "~/components/NoteEditor"
import NoteList from "~/components/NoteList"
import EditIcon from "~/components/icons/EditSVG"
import PrintIcon from "~/components/icons/PrintSVG"
import { useGetCare, useReloadCare, useGetNote, useReloadNote } from "~/hooks/api"
import { careService } from "~/services/care"
import { noteService } from "~/services/note"
import { occurrenceService } from "~/services/occurrence"

import "./CalendarPage.scss"

function CalendarPage(props) {
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-device-width: 1224px)",
  })

  const [date, setDate] = useState(new Date())
  const [noteEditorShown, setNoteEditorShown] = useState(false)
  const [printable, setPrintable] = useState(false)

  const calendarRef = useRef()

  const { isLoading: isCareLoading, error: careError, data: cares } = useGetCare(date)
  const { isLoading: isNoteLoading, error: noteError, data: notes } = useGetNote(date)
  const reloadCare = useReloadCare()
  const reloadNote = useReloadNote()

  const dateOptions = { day: "numeric", month: "long", weekday: "long", year: "numeric" }

  const handleBeforeGetContent = () => {
    return new Promise((resolve, reject) => {
      setPrintable(true)
      resolve()
    })
  }

  const handlePrint = useReactToPrint({
    content: () => calendarRef.current,
    onAfterPrint: () => {
      setPrintable(false)
    },
    onBeforeGetContent: handleBeforeGetContent,
  })

  if (careError) {
    return <p>{careError.message}</p>
  }
  if (noteError) {
    return <p>{noteError.message}</p>
  }

  const morning = cares?.morning
  const midday = cares?.midday
  const evening = cares?.evening

  const changeAttribution = (dayPart, patientIndex, careIndex, newNurseId) => {
    const dayPartToStateMap = { evening, midday, morning }
    const myDayPart = dayPartToStateMap[dayPart]
    const occurenceId = myDayPart[patientIndex].list_cares[careIndex].occurrence_id

    careService.assign(occurenceId, dayPart, newNurseId).then(() => {
      reloadCare(date)
    })
  }

  const addException = occurrenceId => {
    occurrenceService.add_exception(occurrenceId).then(() => {
      reloadCare(date)
    })
  }

  const addNote = (data, callback) => {
    noteService.create(data).then(note_id => {
      callback()
      reloadNote(date)
    })
  }

  const deleteNote = (note_index, note_id) => {
    noteService.remove(note_id).then(() => {
      reloadNote(date)
    })
  }

  return (
    <div ref={calendarRef}>
      <div className="title">
        {isDesktopOrLaptop ? (
          <h1>{date.toLocaleDateString("fr-FR", dateOptions)}</h1>
        ) : (
          <h1>{date.toLocaleDateString("fr-FR")}</h1>
        )}
      </div>
      <div>
        {!printable && (
          <div className="calendar">
            <Calendar locale="fr" value={date} onChange={setDate} />
          </div>
        )}
      </div>
      <div>
        {isCareLoading ? (
          <div className="loader">
            <ClipLoader color={"#6B6F8F"} size={100} />
          </div>
        ) : (
          <GenericCalendarPage
            addException={addException}
            cares={cares}
            changeAttribution={changeAttribution}
            personal={false}
            printable={printable}
          />
        )}
      </div>
      {!printable && (
        <div className="print">
          <IconButton className="printIcon" id="print" onClick={handlePrint}>
            <PrintIcon />
            {isDesktopOrLaptop && <div className="printText">Imprimer</div>}
          </IconButton>
        </div>
      )}
      {!printable && (
        <div className="noteContainer">
          <h2>Notes</h2>
          {isNoteLoading ? (
            <div className="loader">
              <ClipLoader color={"#6B6F8F"} size={100} />
            </div>
          ) : (
            <NoteList deleteNote={deleteNote} notes={notes} />
          )}

          <>
            <Button
              className="addNote"
              variant="contained"
              onClick={() => setNoteEditorShown(true)}
            >
              <EditIcon />
              Ajouter une note
            </Button>
            <NoteEditor
              addNote={addNote}
              editorShown={noteEditorShown}
              setEditorShown={setNoteEditorShown}
            />
          </>
        </div>
      )}
    </div>
  )
}

export { CalendarPage }
