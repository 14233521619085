import IconButton from "@material-ui/core/IconButton"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction"
import ListItemText from "@material-ui/core/ListItemText"
import { makeStyles } from "@material-ui/core/styles"
import DeleteIcon from "@material-ui/icons/Delete"
import React, { useContext } from "react"
import { NursesContext } from "~/helpers/context"

const useStyles = makeStyles(theme => ({
  demo: {
    backgroundColor: theme.palette.background.paper,
  },
  root: {
    flexGrow: 1,
    maxWidth: 752,
  },
  title: {
    margin: theme.spacing(4, 0, 2),
  },
}))

export default function InteractiveList(props) {
  const handleDeleteNote = (index, note_id) => event => {
    props.deleteNote(index, note_id)
  }

  const classes = useStyles()
  const nursesContext = useContext(NursesContext)

  const get_nurse_name = nurse_id => {
    const found = nursesContext.values.nursesList.find(element => element["nurse_id"] === nurse_id)
    if (found) {
      return found["name"]
    }
    return null
  }

  return (
    <div className={classes.root} id="noteList">
      <List>
        {props.notes.map((element, index) => (
          <ListItem key={index}>
            <ListItemText primary={element.message} secondary={get_nurse_name(element.nurse_id)} />
            <ListItemSecondaryAction>
              <IconButton
                aria-label="delete"
                edge="end"
                onClick={handleDeleteNote(index, element.note_id)}
              >
                <DeleteIcon />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        ))}
      </List>
    </div>
  )
}
