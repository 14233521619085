import MaterialTable from "material-table"
import React from "react"
import { userService } from "~/services/user"

const SHOWN_PASSWORD = "********"

export default function UserTable({ users, setUsers }) {
  const refresh = React.useCallback(() => {
    userService.get().then(user_list => {
      user_list.forEach(user => {
        user.password = SHOWN_PASSWORD
      })
      setUsers(user_list)
    })
  }, [setUsers])

  React.useEffect(() => {
    refresh()
  }, [refresh])

  const onRowAdd = newData =>
    new Promise((resolve, reject) => {
      userService.create(newData).then(() => {
        refresh()
        resolve()
      })
    })

  const onRowUpdate = (newData, oldData) =>
    new Promise((resolve, reject) => {
      if (newData.password === SHOWN_PASSWORD) {
        delete newData.password
      }
      userService.update(newData).then(() => {
        refresh()
        resolve()
      })
    })

  const onRowDelete = oldData =>
    new Promise((resolve, reject) => {
      userService.remove(oldData.id).then(() => {
        refresh()
        resolve()
      })
    })

  return (
    <MaterialTable
      columns={[
        { field: "username", title: "Login" },
        { field: "password", title: "Mot de passe" },
        { field: "active", initialEditValue: true, title: "Actif", type: "boolean" },
        {
          field: "role",
          initialEditValue: "standard",
          lookup: { admin: "admin", standard: "standard" },
          title: "Rôle",
        },
      ]}
      data={users}
      editable={{ onRowAdd, onRowDelete, onRowUpdate }}
      localization={{
        body: {
          addTooltip: "Ajouter",
          deleteTooltip: "Supprimer",
          editRow: {
            cancelTooltip: "Annuler",
            saveTooltip: "Sauver",
          },
          editTooltip: "Modifier",
          emptyDataSourceMessage: "Aucune donnée à afficher",
        },
        pagination: {
          firstTooltip: "Première page",
          labelDisplayedRows: "{from}-{to} sur {count}",
          labelRowsSelect: "lignes",
          lastTooltip: "Dernière page",
          nextTooltip: "Suivant",
          previousTooltip: "Précédent",
        },
        toolbar: {
          searchPlaceholder: "Rechercher",
        },
      }}
      title="Utilisateurs"
    />
  )
}
