import SvgIcon from "@material-ui/core/SvgIcon"
import React from "react"

const EditIcon = props => (
  <SvgIcon viewBox="0 0 27 27">
    <g transform="translate(1.5 1.5)"></g>
    <path
      className="editIconSvg"
      d="M20.65,4.232a3.328,3.328,0,0,1,4.707,4.707L9.472,24.824,3,26.589l1.765-6.472Z"
      transform="translate(-1.5 -1.757)"
    />
  </SvgIcon>
)

export default EditIcon
