export const CARES = [
  {
    color: "#4BB5C1",
    label: "Pst",
  },
  {
    color: "#52251C",
    label: "Médicament",
  },
  {
    color: "#52251C",
    label: "Medic",
  },
  {
    color: "#FF0000",
    label: "P. de sang A jeun",
  },
  {
    color: "#FF0000",
    label: "P. de sang Non a jeun",
  },
  {
    color: "#FF0000",
    label: "Prise de sang TP",
  },
  {
    color: "#FF0000",
    label: "Prise de sang Plaq",
  },
  {
    color: "#FF0000",
    label: "PSAj",
  },
  {
    color: "#FF0000",
    label: "PSNAj",
  },
  {
    color: "#FF0000",
    label: "TP",
  },
  {
    color: "#FF0000",
    label: "plaq",
  },
  {
    color: "#BE2802",
    label: "Prise de tension TA",
  },
  {
    color: "#BE2802",
    label: "TA",
  },
  {
    color: "#7FA756",
    label: "Patch",
  },
  {
    color: "#4A1A2C",
    label: "Bande à varices",
  },

  {
    color: "#4A1A2C",
    label: "B.A.V",
  },
  {
    color: "#FC7F3C",
    label: "Anticoagulant",
  },
  {
    color: "#B5E655",
    label: "Diab.",
  },
  {
    color: "	#DE3ED4",
    label: "Alim. enterale",
  },
  {
    color: "#1C7709",
    label: "I.M Sous cutanée",
  },
  {
    color: "#1C7709",
    label: "IM SC",
  },
  {
    color: "#DB0073",
    label: "I.V Perf",
  },
  {
    color: "#3366ff",
    label: "Autre",
  },
  {
    color: "#3333ff",
    label: "Soir",
  },
  {
    color: "#0C047E",
    label: "Cabinet",
  },
  {
    color: "#0C047E",
    label: "Cab.",
  },
  {
    color: "#0C047E",
    label: "Vaccin",
  },
  {
    color: "#0C047E",
    label: "Sonde urinaire",
  },
  {
    color: "#0C047E",
    label: "Semainier",
  },
]
