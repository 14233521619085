import React from "react"
import ClipLoader from "react-spinners/ClipLoader"
import CareTable from "./CareTable"
import PatientSelect from "~/components/PatientSelect"
import { usePatients } from "~/hooks/api"
import { careService } from "~/services/care"
import { patientService } from "~/services/patient"

export default function CareComponent(props) {
  const [cares, setCares] = React.useState([])
  const [selectedPatient, setSelectedPatient] = React.useState(null)
  const { isLoading, error, data: patientList } = usePatients()

  const addCare = (data, callback) => {
    careService.create(data).then(createdCare => {
      setCares([createdCare, ...cares])
      callback()
    })
  }

  React.useEffect(() => {
    if (selectedPatient) {
      patientService.get_one(selectedPatient).then(patient => {
        setCares(patient.cares)
      })
    }
  }, [selectedPatient])

  if (error) {
    return <p>{error.message}</p>
  }

  if (isLoading) {
    return (
      <div className="loader">
        <ClipLoader color={"#6B6F8F"} size={100} />
      </div>
    )
  }

  const refresh = () => {
    if (selectedPatient) {
      patientService.get_one(selectedPatient).then(patient => {
        setCares(patient.cares)
      })
    }
  }

  return (
    <div className="cares">
      <div className="patientSelection">
        <PatientSelect
          patients={patientList}
          selectedPatient={selectedPatient}
          setSelectedPatient={setSelectedPatient}
        />
      </div>
      <hr />
      {selectedPatient && (
        <div className="careTable">
          <CareTable
            addCare={addCare}
            cares={cares}
            patientId={selectedPatient}
            refresh={refresh}
            setCares={setCares}
          />
        </div>
      )}
    </div>
  )
}
