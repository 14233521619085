import React, { useContext } from "react"
import { Redirect } from "react-router-dom"
import NurseTable from "./NurseTable"
import styles from "./UserAdminPage.module.scss"
import UserTable from "./UserTable"
import { UserContext } from "~/helpers/context"

export default function UserAdminPage(props) {
  const [users, setUsers] = React.useState([])
  const [nurses, setNurses] = React.useState([])

  const userContext = useContext(UserContext)

  if (userContext.values.role !== "admin") {
    return (
      <div>
        <Redirect to="/" />
      </div>
    )
  }

  return (
    <div>
      <div className={styles.title}>
        <h1>Administration </h1>
      </div>

      <div className={styles.admincontent}>
        <UserTable setUsers={setUsers} users={users} />
        <NurseTable nurses={nurses} setNurses={setNurses} users={users} />
      </div>
    </div>
  )
}
