import Button from "@material-ui/core/Button"
import IconButton from "@material-ui/core/IconButton"
import Snackbar from "@material-ui/core/Snackbar"
import { makeStyles } from "@material-ui/core/styles"
import CloseIcon from "@material-ui/icons/Close"
import React from "react"

const useStyles = makeStyles(theme => ({
  button: {
    color: "red",
  },
  close: {
    padding: theme.spacing(0.5),
  },
  root: {
    backgroundColor: "#404040",
    color: "#ffffff",
  },
}))

export default function SimpleSnackbar(props) {
  const classes = useStyles()

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return
    }

    props.setOpen(false)
  }

  const onClick = () => {
    window.location.href = "/frontend/login"
  }

  return (
    <Snackbar
      action={[
        <Button
          key="logout"
          className={classes.root}
          style={{ border: "1px solid #ffffff" }}
          onClick={onClick}
        >
          NOT ME
        </Button>,
        <IconButton
          key="close"
          aria-label="close"
          className={classes.close}
          color="inherit"
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>,
      ]}
      anchorOrigin={{
        horizontal: "center",
        vertical: "bottom",
      }}
      autoHideDuration={6000}
      ContentProps={{
        "aria-describedby": "message-id",
        classes: {
          root: classes.root,
        },
      }}
      message={<span>Logged as {props.username}</span>}
      open={props.open}
      onClose={handleClose}
    />
  )
}
