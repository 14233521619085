import { useQuery, useQueryCache } from "react-query"
import { careService } from "~/services/care"
import { noteService } from "~/services/note"
import { patientService } from "~/services/patient"

export const usePatients = () => {
  return useQuery("patients", () => patientService.get())
}

export const useReloadPatients = () => {
  const queryCache = useQueryCache()
  return () => queryCache.invalidateQueries("patients")
}

export const useGetCare = date => {
  const [day, month, year] = date.toLocaleDateString().split("/")
  const date_str = "" + year + "-" + month + "-" + day
  return useQuery(["get_care", date_str], () => careService.get_for_day(date_str))
}

export const useReloadCare = () => {
  const queryCache = useQueryCache()
  return date => {
    const [day, month, year] = date.toLocaleDateString().split("/")
    const date_str = "" + year + "-" + month + "-" + day
    return queryCache.invalidateQueries(["get_care", date_str])
  }
}

export const useGetCareForNurse = (date, nurse_id) => {
  const [day, month, year] = date.toLocaleDateString().split("/")
  const date_str = "" + year + "-" + month + "-" + day
  return useQuery(["get_care_for_nurse", date_str, nurse_id], () => {
    if (nurse_id) {
      return careService.get_personal_for_day(nurse_id, date_str)
    }
    return { evening: [], midday: [], morning: [] }
  })
}

export const useGetNote = date => {
  const [day, month, year] = date.toLocaleDateString().split("/")
  const date_str = "" + year + "-" + month + "-" + day
  return useQuery(["get_note", date_str], () => noteService.get(date_str))
}

export const useReloadNote = () => {
  const queryCache = useQueryCache()
  return date => {
    const [day, month, year] = date.toLocaleDateString().split("/")
    const date_str = "" + year + "-" + month + "-" + day
    return queryCache.invalidateQueries(["get_note", date_str])
  }
}
